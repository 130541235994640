import Accordion from "../Accordion";

export default function FAQ() {
    return (
        <>
            <div className='topics'>
                <div className='topics-container'>
                    <div className='topic'>
                        <h1 className='topic-header center'>Frequently Asked Questions</h1>
                        <Accordion />
                    </div>
                </div>
            </div>
        </>
    );
}