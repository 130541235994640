import Leo from '../images/img22.jpg';
import Tim from '../images/img26.jpg';
import Gab from '../images/img12.jpg';
import Stan from '../images/img69.jpg';

export const instructors = [
    {
        name: "Leonardo Gamarra",
        rank: "5th Degree Black Belt (Head Coach & Owner)",
        parOne: "Leo was born in Rio de Janeiro, lived and trained there until 2008 when he moved to the UK. In 1994, Leo joined Murilo Bustamante's club which later became Brazilian Top Team (BTT). Initially, his idea was to acquire some skills so he could defend himself, but as it usually happens with BJJ practitioners, he fell in love with the sport and never left.",
        parTwo: "During his BJJ journey, he had the opportunity to train and learn from famous BJJ masters including Vitor Belfort (former UFC Light Heavyweight champion), Ricardo Libório (co-founder of American Top Team (ATT) and Brazilian Top Team (BTT)), Zé Mário Sperry, Sérgio Gasparelli (Babú), Sérgio “Bolão” Souza, Pedro Duarte, Alvaro Ramos (Vavá) and of course, Murilo Bustamante.",
        parThree: "He has always been a martial arts enthusiast, having practiced karate and aikido under the guidance of his great master and father Paulo Gamarra, an experienced black belt and expert in martial arts for over 40 years. Leo also has a background in boxing and judo.",
        img: Leo,
        reverse: false
    },
    {
        name: "Timothy O'Leary",
        rank: "Black Belt",
        parOne: "Timothy O'Leary has studied martial arts since 2005, including Karate, Boxing, Aikido and Kali before discovering Brazilian Jiu Jitsu in 2007, through training submission grappling with William Watson and Ger O'Dea in Edinburgh.",
        parTwo: "He received his blue belt under Rick Young in 2011 and purple belt under Dave Ginsberg in Boston, USA, in 2015. He was promoted to brown belt in 2019, and in 2022, he recieved his black belt from Professor Leo.",
        parThree: "Tim is leading our early morning classes.",
        img: Tim,
        reverse: true
    },
    {
        name: "Gabriel Wyse",
        rank: "Black Belt",
        parOne: "Gabriel's martial arts journey began in his teenage years, sparked by an interest in MMA after watching the UFC. Initially intent on competing in MMA, he later shifted his focus to gi BJJ, attracted by its technical depth and less brutal nature.",
        parTwo: "Training under Leo, Gabriel earned his purple belt in 2019, brown in 2021, and black belt by 2023. The knowledge he gained from competing, while progressing through the colored belts, has significantly informed his interest and approach to coaching.",
        parThree: "In 2021, Gabriel started teaching BJJ. He now leads the all-levels class on Fridays from 18:45 to 20:00, sharing the insights and knowledge he's acquired through his own experiences in the sport.",
        img: Gab,
        reverse: false
    },
    {
        name: "Stan Janis",
        rank: "Brown Belt",
        parOne: "Stan has been involved in various combat sports for over twenty years. He has a black belt in karate and has represented his country, the Czech Republic, internationally at the black belt level in karate. He has many years of experience in Thai boxing and MMA along with Japanese martial arts.",
        parTwo: "Stan started BJJ under master Leonardo 8 years ago up until now.",
        parThree: "As an avid Mindfulness and Yoga practitioner, martial arts are much more than just a physical activity, and he tries to implement these practices in his BJJ classes.",
        img: Stan,
        reverse: true
    }
];