export default function PriceItem({header, description, subDesc}) {
    return (
        <>
            <li className="prices_item">
                    <div className="prices_item_info">
                        <h3 className="prices_item_header">{header}</h3>
                        <p className="prices_item_text">{description}</p>
                    </div>
            </li>
        </>
    );
}