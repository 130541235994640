import React from "react";
import styled from "styled-components";
import './InstructorSection.css';
import '../HomeSection/HomeSection.css';

const ColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.4;
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '2' : '1')};

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p {
        margin-bottom: 2rem;
    }
`;

const ColumnRight = styled.div`
    padding: 1rem 2rem;
    order: ${({reverse}) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        order: ${({reverse}) => (reverse ? '2' : '1')};
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 90%;
            height: 90%;
        }
    }
`;

export default function InstructorSection({ section }) {
    return (
        <section className="instructor-section">
            <div className="section-container">
                <ColumnLeft reverse={section.reverse}>
                    <h1>{section.name}</h1>
                    <p className="top-line">{section.rank}</p>
                    <p>{section.parOne}</p>
                    <p>{section.parTwo}</p>
                    {section.parThree && <p>{section.parThree}</p>}
                    {section.parFour && <p>{section.parFour}</p>}
                    {section.parFive && <p>{section.parFive}</p>}
                </ColumnLeft>
                <ColumnRight reverse={section.reverse}>
                    <img src={section.img} alt={section.alt} />
                </ColumnRight>
            </div>
        </section>
    );
}