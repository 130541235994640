import '../pages/Pages.css';

export default function Timetable() {
    return (
        <>
            <div className=''>
                <div className='topics-container'>
                    <div className='topic timetable'>
                        <h1 className='topic-header center'>Timetable</h1>
                        <div className='grid'>
                            <table class="schedule">
                                <tr>
                                    <td>
                                        <h4>Mon</h4>
                                    </td>
                                    <td>
                                        17:30 - 18:30<br></br><br></br>
                                        18:30 - 19:30<br></br><br></br>
                                        19:30 - 20:45
                                    </td>
                                    <td>
                                        Gi all levels<br></br><br></br>
                                        Beginners<br></br><br></br>
                                        Gi all levels
                                    </td>
                                    <td>
                                        Gabriel<br></br><br></br>
                                        Leo<br></br><br></br>
                                        Leo
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Tues</h4>
                                    </td>
                                    <td>
                                        06:15 - 07:15<br></br><br></br>
                                        18:00 - 19:00<br></br><br></br>
                                        19:00 - 20:00<br></br><br></br>
                                        20:00 - 21:00
                                    </td>
                                    <td>
                                        Gi all levels<br></br><br></br>
                                        Beginners<br></br><br></br>
                                        Gi all levels<br></br><br></br>
                                        No-gi all levels
                                    </td>
                                    <td>
                                        Tim<br></br><br></br>
                                        Leo<br></br><br></br>
                                        Leo<br></br><br></br>
                                        Adam
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Weds</h4>
                                    </td>
                                    <td>
                                        18:30 - 19:30<br></br><br></br>
                                        19:30 - 20:45
                                    </td>
                                    <td>
                                        Gi Intermediate<br></br><br></br>
                                        Gi all levels
                                    </td>
                                    <td>
                                        Leo<br></br><br></br>
                                        Stan
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Thurs</h4>
                                    </td>
                                    <td>
                                        06:15 - 07:15<br></br><br></br>
                                        18:30 - 19:30<br></br><br></br>
                                        19:30 - 20:45
                                    </td>
                                    <td>
                                        Gi all levels<br></br><br></br>
                                        Beginners<br></br><br></br>
                                        Gi all levels
                                    </td>
                                    <td>
                                        Tim<br></br><br></br>
                                        Leo<br></br><br></br>
                                        Leo
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Fri</h4>
                                    </td>
                                    <td>
                                        17:45 - 18:45<br></br><br></br>
                                        18:45 - 19:45
                                    </td>
                                    <td>
                                        No-gi all levels<br></br><br></br>
                                        Gi all levels
                                    </td>
                                    <td>
                                        Tim<br></br><br></br>
                                        Gabriel
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Sat</h4>
                                    </td>
                                    <td>
                                        10:00 - 11:30<br></br>
                                    </td>
                                    <td>
                                        Gi all levels<br></br>
                                    </td>
                                    <td>
                                        Leo<br></br>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}