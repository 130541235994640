export const classPics = [
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/classes/img69.jpg'
];

export const gradingOne = [
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img76.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img77.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img80.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img81.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img82.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img83.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2019/img84.jpg'
];

export const gradingTwo = [
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2022/img25.jpg'
];

export const gradingThree = [
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2023/team.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2023/team2.jpg'
];

export const gradingFour = [
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img76.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img77.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img80.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img81.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img82.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img83.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img84.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img85.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img86.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img87.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img88.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img89.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img90.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img91.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img92.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img93.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img94.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img95.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img96.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img97.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img98.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img99.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img100.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img101.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img102.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img103.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img104.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img105.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img106.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img107.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img108.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img109.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img110.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img111.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img112.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img113.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img114.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img115.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img116.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img117.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img118.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img119.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img120.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img121.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img122.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img123.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img124.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img125.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img126.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img127.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img128.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img129.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img130.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img131.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img132.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img133.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img134.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img135.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img136.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img137.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img138.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img139.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img140.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img141.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img142.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img143.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img144.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img145.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img146.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img147.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img148.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img149.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img150.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img151.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img152.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img153.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img154.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img155.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img156.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img157.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img158.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img159.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img160.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img161.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img162.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img163.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img164.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img165.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img166.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img167.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img168.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img169.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img170.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img171.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img172.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img173.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img174.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img175.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img176.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img177.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img178.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img179.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img180.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img181.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img182.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img183.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img184.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img185.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img186.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img187.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img188.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img189.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img190.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img191.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img192.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img193.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img194.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img195.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img196.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img197.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img198.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img199.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img200.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img201.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img202.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/grading-2024/img203.jpg',
];

export const hiago = [
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img76.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img77.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img80.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img81.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img82.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img83.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img84.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img85.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img86.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img87.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img88.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img89.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img90.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img91.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img92.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img93.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img94.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img95.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img96.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img97.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img98.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img99.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img100.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img101.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img102.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img103.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img104.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img105.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img106.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/hiago-19/img107.jpg'
];

export const thamara = [
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/thamara-19/img75.jpg'
];

export const ighorOne = [
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img76.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img77.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img80.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img81.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img82.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img83.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img84.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img85.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img86.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img87.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img88.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img89.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img90.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img91.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img92.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img93.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img94.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img95.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img96.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img97.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img98.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img99.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img100.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img101.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img102.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img103.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img104.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-19/img105.jpg'
];

export const ighorTwo = [
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img76.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img77.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img80.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img81.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img82.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img83.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img84.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img85.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img86.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img87.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img88.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img89.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img90.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img91.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img92.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img93.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img94.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img95.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img96.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img97.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img98.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img99.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img100.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img101.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img102.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img103.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img104.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img105.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img106.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img107.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img108.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img109.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img110.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img111.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img112.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img113.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img114.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img115.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img116.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img117.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/ighor-horta-20/img118.jpg'
];

export const leoMach = [
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/leonardo-machado-21/img56.jpg'
];

export const murilloOne = [
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img51.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img52.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img53.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img54.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img55.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img56.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img57.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img58.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img59.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img60.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img61.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img62.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img63.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img64.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img65.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img66.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img76.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img77.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img80.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img81.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img82.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img83.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img84.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img85.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img86.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img87.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img88.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img89.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img90.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img91.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img92.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img93.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img94.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img95.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img96.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img97.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img98.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img99.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img100.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img101.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img102.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img103.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img104.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img105.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img106.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img107.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img108.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img109.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img110.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img111.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img112.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img113.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img114.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img115.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img116.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img117.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img118.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img119.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img120.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img121.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img122.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img123.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img124.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img125.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img126.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img127.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2019/img128.jpg'
];

export const murilloTwo = [
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2022/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2022/img5.jpg'
];

export const murilloThree = [
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img1.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img2.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img3.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img4.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img5.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img6.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img7.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img8.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img9.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img10.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img11.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img12.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img13.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img14.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img15.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img16.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img17.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img18.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img19.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img20.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img21.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img22.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img23.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img24.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img25.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img26.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img27.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img28.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img29.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img30.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img31.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img32.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img33.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img34.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img35.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img36.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img37.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img38.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img39.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img40.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img41.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img42.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img43.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img44.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img45.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img46.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img47.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img48.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img49.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img50.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img67.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img68.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img69.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img70.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img71.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img72.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img73.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img74.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img75.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img77.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img78.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img79.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img81.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img82.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img83.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img84.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img85.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img86.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img87.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img88.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img89.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img90.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img91.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img92.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img93.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img94.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img95.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img96.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img97.JPG',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img98.jpg',
    'https://ik.imagekit.io/gtvhhg6wd/images/murillo-2023/img99.jpg'
];