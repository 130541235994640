import { Link } from "react-router-dom";

export default function CardItem({ src, text, label, path, images }) {
    return (
        <>
            <li className="cards_item">
                <Link className="cards_item_link" to={path} state={{ images }}>
                    <figure className="cards_item_pic-wrap" data-category={label}>
                        <img src={src} alt="activity" className="cards_item_img" />
                    </figure>
                    <div className="cards_item_info">
                        <h5 className="cards_item_text">{text}</h5>
                    </div>
                </Link>
            </li>
        </>
    );
}