import './Button.css';
import { Link } from 'react-router-dom';

const defaultStyles = ['btn--primary', 'btn--secondary', 'btn--outline'];
const defaultSizes = ['btn--medium', 'btn--large'];

export default function Button({ children, type, onClick, buttonStyle, buttonSize, path, newTab}) {
    const checkButtonStyle = defaultStyles.includes(buttonStyle) ? buttonStyle : defaultStyles[0];
    const checkButtonSize = defaultSizes.includes(buttonSize) ? buttonSize : defaultSizes[0];
    
    return newTab ? (
        <Link to={path} target="_blank" rel="noopener noreferrer" className='btn-mobile'>
            <button 
                className={`btn ${checkButtonStyle} ${checkButtonSize}`} 
                onClick={onClick} 
                type={type}>
                {children}
            </button>
        </Link>
    ) : (
        <Link to={path} className='btn-mobile'>
            <button 
                className={`btn ${checkButtonStyle} ${checkButtonSize}`} 
                onClick={onClick} 
                type={type}>
                {children}
            </button>
        </Link>
    );
}