import { classPics, gradingOne, gradingTwo, gradingThree, gradingFour, hiago, thamara, ighorOne, ighorTwo, leoMach, murilloOne, murilloTwo, murilloThree } from "../SourceFiles";
import '../CardItem/Cards.css';
import CardItem from "../CardItem";

export default function Gallery() {
    return (
        <>
            <div className="cards">
                <h1 className='cards-section-header'>Gallery</h1>
                <div className="cards_container">
                    <div className="cards_wrapper">
                        <ul className="cards_items">
                            <CardItem
                                src='/images/img1.jpg'
                                text='Class Pictures'
                                label='Classes'
                                path='/imageViewer'
                                images={classPics}
                            />
                            <CardItem
                                src='/images/img75.jpg'
                                text='Grading 2019'
                                label='Grading'
                                path='/imageViewer'
                                images={gradingOne}
                            />
                            <CardItem
                                src='/images/img23.jpg'
                                text='Grading 2022'
                                label='Grading'
                                path='/imageViewer'
                                images={gradingTwo}
                            />
                        </ul>
                        <ul className="cards_items">
                            <CardItem
                                src='/images/team.jpg'
                                text='Grading 2023'
                                label='Grading'
                                path='/imageViewer'
                                images={gradingThree}
                            />
                            <CardItem
                                src='/images/team1.jpg'
                                text='Grading 2024'
                                label='Grading'
                                path='/imageViewer'
                                images={gradingFour}
                            />
                            <CardItem
                                src='/images/img89.jpg'
                                text='Hiago George seminar 2019'
                                label='Seminar'
                                path='/imageViewer'
                                images={hiago}
                            />
                        </ul>
                        <ul className="cards_items">
                            <CardItem
                                src='/images/img63.jpg'
                                text='Thamara seminar 2019'
                                label='Seminar'
                                path='/imageViewer'
                                images={thamara}
                            />
                            <CardItem
                                src={ighorOne[102]}
                                text='Ighor Horta seminar 2019'
                                label='Seminar'
                                path='/imageViewer'
                                images={ighorOne}
                            />
                            <CardItem
                                src='/images/img1-1.jpg'
                                text='Murillo Bustamante seminar 2019'
                                label='Seminar'
                                path='/imageViewer'
                                images={murilloOne}
                            />
                        </ul>
                        <ul className="cards_items">
                            <CardItem
                                src='/images/img118.jpg'
                                text='Ighor Horta seminar 2020'
                                label='Seminar'
                                path='/imageViewer'
                                images={ighorTwo}
                            />
                            <CardItem
                                src='/images/img54.jpg'
                                text='Leonardo Machado seminar 2021'
                                label='Seminar'
                                path='/imageViewer'
                                images={leoMach}
                            />
                            <CardItem
                                src='/images/img3.jpg'
                                text='Murillo Bustamante seminar 2022'
                                label='Seminar'
                                path='/imageViewer'
                                images={murilloTwo}
                            />
                        </ul>
                        <ul className="cards-items">
                            <CardItem
                                src='/images/img6.jpg'
                                text='Murillo Bustamante seminar 2023'
                                label='Seminar'
                                path='/imageViewer'
                                images={murilloThree}
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}