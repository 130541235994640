import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar';
import Home from '../components/pages/Home';
import About from '../components/pages/About';
import Instructors from '../components/pages/Instructors';
import Classes from '../components/pages/Classes';
import Gallery from '../components/pages/Gallery';
import ImageViewer from '../components/ImageViewer';
import Footer from '../components/Footer';

export default function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/instructors' element={<Instructors />} />
          <Route path='/classes' element={<Classes />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/imageViewer' element={<ImageViewer />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
