import { LazyLoadImage } from "react-lazy-load-image-component";
import { useInView } from 'react-intersection-observer';
import { useState } from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useLocation } from "react-router-dom";
import "./ImageViewer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function ImageViewer() {
  const location = useLocation()
  const { images } = location.state;
  const { ref } = useInView();
  const [slideNbr, setSlideNbr] = useState(0);
  const [openModel, setOpenModel] = useState(false);

  const handleOpenModel = (index) => {
    setSlideNbr(index);
    setOpenModel(true);
  };

  const closeModel = () => {
    setOpenModel(false);
  };

  const prevSlide = () => {
    slideNbr === 0 ? setSlideNbr(images.length - 1) : setSlideNbr(slideNbr - 1);
  };

  const nextSlide = () => {
    slideNbr === images.length - 1 ? setSlideNbr(0) : setSlideNbr(slideNbr + 1);
  };

  return (
    <>
      {
        openModel &&
        <div className="sliderWrap">
          <FontAwesomeIcon icon={faCircleXmark} className="btnClose" onClick={closeModel} />
          <FontAwesomeIcon icon={faCircleChevronLeft} className="btnPrev" onClick={prevSlide} />
          <FontAwesomeIcon icon={faCircleChevronRight} className="btnNext" onClick={nextSlide} />
          <div className="fullScreenImage">
            <img src={images[slideNbr]} alt="" />
          </div>
        </div>
      }

      <div className="galleryWrap">
        {
          images && images.map((image, index) => {
            return (
              <div
                className="single"
                key={index}
                onClick={() => handleOpenModel(index)}
                ref={ref}
              >
                <LazyLoadImage
                  src={image}
                  width={"100%"}
                  cursor={"pointer"}
                  placeholderSrc={image + '?tr=w-20,h-20'}
                  effect="blur"
                />
              </div>
            )
          })
        }
      </div>
    </>

  );
}