import '../../containers/App.css';
import Prices from '../Prices';
import FAQ from '../FAQ';
import Timetable from '../Timetable';

export default function Classes() {
    return (
        <>
            <h1 className='classes'>CLASSES</h1>
            <Timetable/>
            <Prices/>
            <FAQ/>
        </>
    );
}