import '../../containers/App.css';
import Hero from '../Hero';
import HomeSection from "../HomeSection";
import Testimonials from '../Testimonials';

export default function Home() {
    return (
        <>
            <Hero />
            <div>
                <HomeSection 
                    mode={'light'}
                    imgStart={'img-start'}
                    img={'/images/team1.jpg'}
                    topline={'About'}
                    heading={'The Academy'}
                    subtitle={'The backstory of Brazilian Top Team Cambridge, aims, affiliations and partners'}
                    path={'/about'}
                    btnStyle={'btn--secondary'}
                    btnText={'Know More'}
                />
                <HomeSection 
                    mode={'dark'}
                    imgStart={'text-start'}
                    img={'/images/img29.jpg'}
                    topline={'Instructors'}
                    heading={'Learn From Experts'}
                    subtitle={'We offer high-level instruction in Brazilian Jiu Jitsu, whether you are training for fun, competition or fitness.'}
                    path={'/instructors'}
                    btnStyle={'btn--secondary'}
                    btnText={'Meet The Instructors'}
                />
                <HomeSection 
                    mode={'light'}
                    imgStart={'img-start'}
                    img={'/images/img31.jpg'}
                    topline={'Classes'}
                    heading={'Classes And Membership'}
                    subtitle={'Classes that fit your schedule at accessible prices'}
                    path={'/classes'}
                    btnStyle={'btn--secondary'}
                    btnText={'Classes & Prices'}
                />
                <HomeSection 
                    mode={'dark'}
                    imgStart={'text-start'}
                    img={'/images/img5.jpg'}
                    topline={'Gallery'}
                    heading={'Classes, Seminars & Grading'}
                    subtitle={'Making memories that will last a lifetime'}
                    path={'/gallery'}
                    btnStyle={'btn--secondary'}
                    btnText={'View Pictures'}
                />
            </div>
            <Testimonials />
        </>
    );
}