import Button from '../Button';
import '../../containers/App.css'
import './Hero.css'

export default function Hero() {
    return (
        <div className="intro-container">
            <video src='https://ik.imagekit.io/gtvhhg6wd/images/btt-hero.mp4' muted autoPlay loop playsInline></video>
            <h1>Brazilian Top Team Cambridge</h1>
            <p>Start your journey today!</p>
            <div className='intro-btns'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    path={'https://secure12.clubwise.com/bttcambridge/pos.asp'}
                    newTab={true}
                >
                    JOIN NOW
                </Button>
            </div>
        </div>
    );
}