export const questions = [
    {
        question: "Who can attend?",
        answer: "All classes are for adults, students must be 16 years or above. We do not currently run children classes."
    },
    {
        question: "What are Beginners Classes?",
        answer: "Our dedicated BJJ Beginner classes are designed to give people new to BJJ the opportunity to develop a strong base before joining the more advanced classes. Our coaches will show you the fundamental techniques and drills that will help get you started on your BJJ journey. There is no rolling (sparring) during these classes. If you have never trained BJJ before, you will be required to complete 12 beginners classes before joining the other classes we offer. Initially, it may be better to go to the “Basic BJJ classes” where you will be able to roll (sparring). This is a nice transition between the beginners and the all-levels."
    },
    {
        question: "What do I need to bring to class?",
        answer: "Wear something comfortable and flexible, most people wear shorts and a t-shirt until they have a Gi. Also, remember to bring a drink with you as BJJ can be thirsty work! We also sell academy Gis, so if you're unsure about anything, contact us before you attend your first class and we can help get you ready."
    },
    {
        question: "Do you have previous experience?",
        answer: "You've had some time off the mats, moved to Cambridge or just coming to BJJ from a different discipline e.g. Judo or Wrestling, don't worry we have you covered. We welcome everyone at Brazilian Top Team Cambridge, so if you want to come and join us, get in contact and we'll work with you to find the best route onto the mats."
    },
    {
        question: "What can I expect from an all-levels class?",
        answer: "Classes begin with a warmup, typically followed by strength and conditioning exercises and some light stretching. This prepares the body for the class ahead and helps maintain a healthy level of fitness. After the warmup, the instructor will lead the class in a technique, giving students the opportunity to observe and understand the technique before practising with a partner. Finally, with the exception of beginners classes, students are given time to roll (sparring) with their teammates in short rounds until the end of the class. Rounds are timed and students quickly switch partners before the next round begins. When rolling (sparring), we always recommend wearing a mouthguard. All classes conclude with students lining up in rank order, ensuring belts are tied in Gi classes, and the instructor addresses the class before dismissing them."
    },
    {
        question: "Am I fit enough?",
        answer: "We hear this line a lot and the great news is BJJ will help you get fit very quickly. If you already have a basic level of fitness that's great, but if you don't we can help you get there."
    }
];