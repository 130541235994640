import React, { useState, useEffect } from 'react';
import { reviews } from '../Data/TestimonialData';
import './Testimonials.css';

export default function Testimonials() {
  const [current, setCurrent] = useState(0);

  const switchSlide = (index) => {
    setCurrent(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent(prev => (prev + 1) % reviews.length);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="test-container">
      <div className="test-wrapper">
        <div className="header"><h1>Testimonials</h1></div>

        <div className="testimonials">
          {reviews.map((review, index) => (
            <div key={index} className={`testimonial ${index === current ? 'active' : ''}`}>
              <img src={review.img} alt={review.name} />
              <h3>{review.name}</h3>
              <p>{review.review}</p>
            </div>
          ))}
        </div>

        <div className="indicators">
          {reviews.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === current ? 'active' : ''}`}
              onClick={() => switchSlide(index)}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}