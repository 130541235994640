import './Pages.css';
import '../HomeSection/HomeSection.css';
import InstructorSection from '../InstructorSection';
import { instructors } from '../Data/InstructorData';

export default function Instructors() {
    return (
        <>
            <h1 className='instructors'>Instructors</h1>
            {
                instructors.map((instructor) => {
                    return <InstructorSection section={instructor}/>;
                })
            }
        </>
    );
}