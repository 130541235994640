import './Footer.css';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer-container">
            <div class='footer-links'>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>BTT Cambridge</h2>
                        <Link to='/about'>About</Link>
                        <Link to='/instructors'>Instructors</Link>
                        <Link to='/classes'>Classes</Link>
                        <Link to='/gallery'>Gallery</Link>
                        <Link to='https://secure12.clubwise.com/bttcambridge/pos.asp' target="_blank" rel="noopener noreferrer">Join Now</Link>
                    </div>
                    <div class='footer-link-items'>
                        <h2>Social Media</h2>
                        <Link to='https://www.instagram.com/btt_cambridge' target="_blank" rel="noopener noreferrer">Instagram</Link>
                        <Link to='https://www.facebook.com/bttcambridge' target="_blank" rel="noopener noreferrer">Facebook</Link>
                    </div>
                    <div class='footer-link-items'>
                        <h2>Contact Us</h2>
                        <div className='contact-info'>
                            <i class='fas fa-map-marker-alt' />
                            <div>
                                <p>1st Floor Trinity Centre,</p>
                                <p>24 Cambridge Science Park,</p>
                                <p>Milton Road,</p>
                                <p>Cambridge,</p>
                                <p>CB4 0FN, UK</p>
                            </div>
                        </div>
                        <div className='contact-info'>
                            <i class='far fa-envelope-open' />
                            <p>
                                info@bttcambridge.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section class='footer-rights'>
                <div class='footer-rights-wrap'>
                    <small class='website-rights'>Brazilian Top Team Cambridge © {new Date().getFullYear()}</small>
                </div>
            </section>
        </div>
    );
}