import './Navbar.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

export default function Navbar() {
    const [menuClick, setMenuClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleMenuClick = () => setMenuClick(!menuClick);
    const closeMobileMenu = () => setMenuClick(false);
    const showButton = () => {
        if (window.innerWidth <= 990) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src='https://ik.imagekit.io/gtvhhg6wd/images/btt-logo.png' alt='logo' className='logo' width={'40px'} height={'40px'}/>
                        BTT Cambridge
                    </Link>
                    <div className='menu-icon' onClick={handleMenuClick}>
                        <i className={menuClick ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={menuClick ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                About
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/instructors' className='nav-links' onClick={closeMobileMenu}>
                                Instructors
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/classes' className='nav-links' onClick={closeMobileMenu}>
                                Classes
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/gallery' className='nav-links' onClick={closeMobileMenu}>
                                Gallery
                            </Link>
                        </li>
                        <li>
                            <a
                                href='https://secure12.clubwise.com/bttcambridge/pos.asp'
                                target="_blank"
                                rel="noopener noreferrer"
                                className='nav-links-mobile free'
                                onClick={closeMobileMenu}>
                                Free Trial
                            </a>
                        </li>
                        <li>
                            <a
                                href='https://secure12.clubwise.com/bttcambridge/pos.asp'
                                target="_blank"
                                rel="noopener noreferrer"
                                className='nav-links-mobile'
                                onClick={closeMobileMenu}>
                                Join Now
                            </a>
                        </li>
                    </ul>
                    {
                        button && 
                        <div className='btn-cluster'>
                            <Button path={'https://secure12.clubwise.com/bttcambridge/pos.asp'} buttonStyle={'btn--primary'} newTab={true}>Free Trial</Button>
                            <Button path={'https://secure12.clubwise.com/bttcambridge/pos.asp'} buttonStyle={'btn--outline'} newTab={true}>Join Now</Button>
                        </div>
                    }
                </div>
            </nav>
        </>
    );
}