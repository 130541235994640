import '../../containers/App.css';
import './Pages.css';

export default function About() {
    return (
        <>
            <h1 className='about'>Who We Are</h1>
            <div className='topics'>
                <div className='topics-container'>
                    <div className='topic'>
                        <h1 className='topic-header center'>The Backstory</h1>
                        <img className='partner-img' src='/images/tsunami.jpg' width='100%' cursor='pointer' alt='backstory'/>
                        <h3 className='topic-caption small'>1994 - 2004: From white belt to black belt</h3>
                        <p className='topic-description'>
                            Leo graduated black belt in 2004, 10 years after he started, in a ceremony at Brazilian Top Team Rio de Janeiro,
                            lead by his masters, Murilo Bustamante, Jose Mario Sperry and Bebeo Duarte. During this time, Leo also graduated
                            in Physiotherapy, specialising in sports therapy and acupuncture.
                        </p>
                        <p><br></br></p>
                        <h3 className='topic-caption small'>2004 - 2008: Sharpening his skills</h3>
                        <p className='topic-description'>
                            Over the next 4 years Leo dedicated his time to studying BJJ and honing his skills with some of the world's top practitioners.
                        </p>
                        <p><br></br></p>
                        <h3 className='topic-caption small'>2008 - 2010: Life in London, UK</h3>
                        <p className='topic-description'>
                            Aside from getting used to the cold weather, Leo taught BJJ in Wimbledon for 6 months before moving to Cambridge with his wife.
                        </p>
                        <p><br></br></p>
                        <h3 className='topic-caption small'>2011 - 2018: Tsunami Gym, Cambridge, UK</h3>
                        <p className='topic-description'>
                            Leo is grateful for his time at Tsunami, as he was able to lead an excellent team of extremely capable and respectful athletes.
                            He graded his first black belt <a href='https://www.impulsebjj.co.uk/' target="_blank" rel="noopener noreferrer">Fabio Ferrari</a>, three brown belts and many purple and blue belts.
                        </p>
                        <p><br></br></p>
                        <h3 className='topic-caption small'>2018: The birth of Gama Force BJJ</h3>
                        <p className='topic-description'>
                            In August, a new beginning started for Leo as he founded Gama Force BJJ, his own team.
                        </p>
                        <p><br></br></p>
                        <h3 className='topic-caption small'>2019: Gama Force BJJ becomes Brazilian Top Team Cambridge</h3>
                        <p className='topic-description'>
                            Leo becomes the representative for Brazilian Top Team (BTT) in the UK. Gama Force BJJ is transformed into Brazilian Top Team (BTT) Cambridge
                            and a new chapter begins.
                        </p>
                    </div>
                    <div className='topic'>
                        <h1 className='topic-header center'>Our Aim</h1>
                        <h3 className='topic-caption'>Our aim is to provide the highest-level instruction in Brazilian Jiu Jitsu</h3>
                        <p className='topic-description'>
                            We offer a clean and safe environment to provide you with an enjoyable and friendly atmosphere.
                            We want you to train with passion and motivation but also have great fun doing so.
                            We are kitted out with high-quality Dollamur mats, as used by UFC gyms.
                            <br></br>
                            Our instructor, Leonardo Gamarra, is very experienced and capable of offering extremely high levels
                            of training for all levels, from beginner to the most experienced athlete.
                            <br></br>
                            We promote health and fitness as well as other vital components of martial arts such as self-confidence,
                            respect, integrity, honesty, honour and discipline. At BTT Cambridge, we believe promotion to higher belts
                            needs to reflect a student's character as well as their technical abilities.
                            <br></br>
                        </p>
                    </div>
                    <div className='topic'>
                        <h1 className='topic-header center'>Our Affiliation</h1>
                        <img className='partner-img' src='/images/BTT.jpg' width='100%' cursor='pointer' alt='backstory'/>
                        <h3 className='topic-caption'>We represent Brazilian Top Team (BTT) in the UK</h3>
                        <p className='topic-description'>
                            BTT is one of the most traditional Brazilian Jiu Jitsu clubs in the world. It was established in Rio de Janeiro,
                            Brazil, by <a href='https://en.wikipedia.org/wiki/Murilo_Bustamante' target="_blank" rel="noopener noreferrer"> Murilo Bustamante</a>,
                            <a href='https://en.wikipedia.org/wiki/Ricardo_Libório' target="_blank" rel="noopener noreferrer"> Ricardo Libório</a>,
                            <a href='https://en.wikipedia.org/wiki/Mário_Sperry' target="_blank" rel="noopener noreferrer"> Mário Sperry</a> and
                            <a href='https://www.bjjheroes.com/bjj-fighters/luis-roberto-duarte-bebeo' target="_blank" rel="noopener noreferrer"> Luis Roberto Duarte</a>, former members of the
                            <a href='https://en.wikipedia.org/wiki/Carlson_Gracie' target="_blank" rel="noopener noreferrer"> Carlson Gracie Academy</a>. Currently,
                            BTT has more than <a href='https://www.braziliantopteam.net/site/index.php?lang=en_US&section=home' target="_blank" rel="noopener noreferrer"> 60 clubs</a> around the world.
                        </p>
                    </div>
                    <div className='topic'>
                        <h1 className='topic-header center'>Our Partners</h1>
                        <img className='partner-img' src='/images/revolution1.jpg' width='100%' cursor='pointer' alt='backstory'/>
                        <h3 className='topic-caption'>Revolution Health & Fitness Cambridge Science Park</h3>
                        <p className='topic-description'>
                            Brazilian Top Team Cambridge shares its facilities with Revolution Health & Fitness gym. A superb gym with dedicated and friendly staff
                            who enjoy helping people achieve their fitness goals. The gym is fully equipped with everything you need for a great workout!
                            BTT members get membership discounts as well.
                            Feel free to check out the <a href='http://www.revolutionhealthfitness.co.uk/' target="_blank" rel="noopener noreferrer">gym</a>.
                        </p>
                    </div>
                </div>
            </div>
        </>

    );
}