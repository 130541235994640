import Button from '../Button';
import './HomeSection.css';

export default function HomeSection({ mode, imgStart, img, topline, heading, subtitle, path, btnStyle, btnText }) {
    return (
        <>
            <div className={mode}>
                <div className="info-wrapper">
                    <div className={`info-row ${imgStart}`}>
                        <div className="column1">
                            <div className="text-wrapper">
                                <p className="top-line">{topline}</p>
                                <h1 className="heading">{heading}</h1>
                                <p className="sub-title">{subtitle}</p>
                                <div className="btn-wrap">
                                    <Button buttonStyle={btnStyle} path={path} newTab={false}>{btnText}</Button>
                                </div>
                            </div>
                        </div>
                        <div className='column2'>
                            <div className='img-wrap'>
                                <img className='img' src={img} alt='section-pic' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}