export const reviews = [
    {
        name: "Lisandra Da Silva",
        review: "Personally, I can say that BTT is the best bjj gym in Cambridge! It's spacious, clean and has amazing views! Both the instructors and the team are welcoming and very helpful! Women like myself are made to feel comfortable and welcomed. Leo, the 5th degree black belt and Sensei, has attention to the details, values safety, respect and is aware of each individuals capabilities and needs. I cannot recommend BTT enough!",
        img: "https://lh3.googleusercontent.com/a-/ALV-UjUJB0Da1Mpiir3EXW0btFlrtXN4BatSJ33_Pl4oqCTls6il=p-rp-mo-ba2-br100"
    },
    {
        name: "Angelo Da Rosa",
        review: "Really great experience! Leo is a great instructor and the environment is very friendly to the newcomers. High level BJJ with many high rank practitioners from which you can learn. The friends you make along the way is the greatest thing for me at BTT.",
        img: "https://lh3.googleusercontent.com/a-/ALV-UjUN_4ZLMLdP_b_I55AyU-F2aJbk9eJiqQ3jVTOCcej_uumf=p-rp-mo-br100"
    },
    {
        name: "Neil Robertson",
        review: "I've been training here for around a year and can't recommend it highly enough. A friendly environment and great people to train with. Leo is also amazing, an extremely knowledgeable jiu-jitsu coach but also a great person who teaches valuable life lessons.",
        img: "https://lh3.googleusercontent.com/a-/ALV-UjXG4o0oQ1OsBHKnK0S77uX8z9Oax9DNk33Z-2zxxE68kq-l=p-rp-mo-br100"
    },
    {
        name: "Anna Malan",
        review: "I was a complete beginner to bjj six months ago when I started training with Leo, and can't recommend BTT Cambridge enough. The beginners' course was a great entry into bjj and Leo is an amazing instructor. I'm hooked, and can't wait to keep improving with Leo's instruction. Great to see more women getting involved too!",
        img: "https://lh3.googleusercontent.com/a-/ALV-UjWQJWCGUWCUQ1JBcLitTiGB-BrL91TM7msRzJngcfq1xA=p-rp-mo-br100"
    },
    {
        name: "Armando Sánchez-Lara",
        review: "Best jiu jitsu School. My life style has been modified for good since I joined BTT. Great environment and very professional and patient professors.",
        img: "https://lh3.googleusercontent.com/a-/ALV-UjXzYMYcPh4cPcjeTNNIH6MujNa3JV-dEilLBdczmNema2c=p-rp-mo-br100"
    },
    {
        name: "Andrew Balmer",
        review: "Great place and very fun learning jiujitsu with Leo!",
        img: "https://lh3.googleusercontent.com/a-/ALV-UjXypFxOuUPLOhwV8XcDKtGs_tOZbCLLBZRBSoU0n9U0aEI=p-rp-mo-br100"
    }
];